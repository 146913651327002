<template>
	<v-dialog v-model="dialog" max-width="400">
		<template v-slot:activator="{ on, attrs }">
			<v-btn x-small plain text v-bind="attrs" v-on="on">
				<span>{{ webUserInfo[0].webUserName }}</span>
			</v-btn>
		</template>

		<v-card class="elevation-10 mx-auto" rounded>
			<v-card-title>webUserInfo</v-card-title>
			<v-card-subtitle>{{ webUserInfo.webUserName }}</v-card-subtitle>
			<v-divider />
			<v-card-text>
				<v-data-table
					hide-default-footer
					disable-sort
					disable-pagination
					mobile-breakpoint="5000"
					:items="webUserInfo"
					:headers="headers"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn text plain v-on:click.stop="dialog = false">close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
		name: "ProfileDialog",
		data: () => ({
			dialog: false,
			headers: [
				{ text: "webUserId", value: "webUserId" },
				{ text: "webUserName", value: "webUserName" },
				{ text: "accountType", value: "accountType" },
				{ text: "customerId", value: "customerId" },
				{ text: "languageId", value: "languageId" },
				{ text: "hasDeferredPayment", value: "hasDeferredPayment" },
				{ text: "hasPrivateCatalog", value: "hasPrivateCatalog" },
				{ text: "privateCatalogId", value: "privateCatalogId" },
				{ text: "hasQuote", value: "hasQuote" },
			],
		}),
		computed: {
			...mapGetters({
				webUserInfo: "session/webUserInfo",
			}),
		},
	};
</script>
