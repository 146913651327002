import caas from "@/services/caas";

// STATE
const state = {
    accountInfo: {
        statusInfo: {},
        userInfo: {},
        userAddress: {},
        companyInfo: {
            companyAddress: {},
        },
    },
    quotes: [],
};

// ACTIONS
const actions = {
    fetchWebUserProfile: (context) => {
        var params = { webUserId: null };
        return caas.rpc("getWebUserProfile", params).then((response) => {
            context.commit("SET_ACCOUNT_INFO", response);
        });
    },
    fetchWebUserQuotes: (context) => {
        var params = { webUserId: null };
        return caas.rpc("getWebUserQuotes", params).then((response) => {
            context.commit("SET_QUOTES", response.webUserQuotes);
        });
    },
};

// MUTATIONS
const mutations = {
    SET_ACCOUNT_INFO: (state, payload) => {
        state.accountInfo = payload;
    },
    SET_QUOTES: (state, payload) => {
        state.quotes = payload;
    },
};

// GETTERS
const getters = {
    defaultAddress: (state) => {
        if (state.accountInfo.statusInfo.accountType === "B2C") {
            return state.accountInfo.userAddress;
        } else {
            return state.accountInfo.companyInfo.companyAddress;
        }
    },
    deliveryAddresses: (state, getters) => {
        var a = [];
        var b = getters.defaultAddress;
        if (b) {
            b.addressType = "default";
            b.addressId = 0;
            a.push(b);
        }
        try {
            state.accountInfo.companyInfo.otherAddresses.forEach((record) => {
                if (record.addressType === "deliveryAddress") {
                    a.push(record);
                }
            });
        } catch (err) {
            return a;
        }
        return a;
    },
    invoiceAddresses: (state, getters) => {
        var a = [];
        var b = getters.defaultAddress;
        if (b) {
            b.addressType = "default";
            b.addressId = 0;
            a.push(b);
        }
        try {
            state.accountInfo.companyInfo.otherAddresses.forEach((record) => {
                if (record.addressType === "invoiceAddress") {
                    a.push(record);
                }
            });
        } catch (err) {
            return a;
        }
        return a;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
