import caas from "@/services/caas";
import store from "..";

// STATE
const state = {
    catalogId: 1,
    categoryList: [],
    itemList: [],
    ui: {
        currentCategory: {},
        searchText: "",
        currentPage: 1,
        itemsPerPage: 10,
    },
};

// ACTIONS
const actions = {
    emptyCatalog: (context) => {
        context.commit("SET_CURRENT_CATALOG_ID", 0);
        context.commit("SET_CATEGORY_LIST", []);
        context.commit("SET_CURRENT_CATEGORY", {});
        context.commit("SET_ITEM_LIST", []);
        context.commit("SET_SEARCH_TEXT", "");
    },
    fetchCatalog: (context) => {
        let params = {
            catalogId: state.catalogId,
            webUserId: null,
            languageId: null,
        };
        return caas.rpc("getCatalogTV", params).then((response) => {
            context.commit("SET_CURRENT_CATALOG_ID", state.catalogId);
            context.commit("SET_CATEGORY_LIST", response);
        });
    },
    fetchCatalogItemList: (context) => {
        var params = { languageId: null };
        if (state.ui.searchText) {
            params.categoryId = 0;
            params.itemSearch = state.ui.searchText;
            params.pageNr = 1;
            params.pageRows = -1;
        } else {
            params.itemSearch = "";
            params.categoryId = state.ui.currentCategory.categoryId;
            params.pageNr = state.ui.currentPage;
            params.pageRows = state.ui.itemsPerPage;
        }
        return caas.rpc("getCatalogItemLV", params).then((response) => {
            if (response) {
                context.commit("SET_ITEM_LIST", response);
            }
        });
    },
    fetchCatalogItemDetail: (context, pItem) => {
        let params = {
            itemId: pItem.itemId,
            webUserId: null,
            languageId: null,
        };
        return caas.rpc("getCatalogItemDetail", params).then((response) => {
            context.commit("SET_ITEM_DETAIL", response);
        });
    },
    fetchPrivateCatalog: (context) => {
        var params = {};
        params.webUserId = null;
        params.pageNr = 1;
        params.pageRows = 999;
        return caas.rpc("getWebUserItemLV", params).then((response) => {
            if (response) {
                context.commit("SET_ITEM_LIST", response);
            }
        });
    },
    setCatalogId: (context, pCatalogId) => {
        var x = context.state.catalogId;
        if (x != pCatalogId) {
            store.dispatch(
                "session/showBottomMessage",
                "switching catalog: " + x + " > " + pCatalogId
            );
            context.commit("SET_CATEGORY_LIST", []);
            context.commit("SET_CURRENT_CATEGORY", {});
            context.commit("SET_ITEM_LIST", []);
            context.commit("SET_SEARCH_TEXT", "");
        }
        context.commit("SET_CATALOG_ID", pCatalogId);
    },
    setCurrentCategory: (context, pCatalogTV) => {
        let currentCategory = {
            categoryId: pCatalogTV.id,
            categoryName: pCatalogTV.name,
            catalogItemCount: pCatalogTV.catalogItemCount,
        };
        context.commit("SET_CURRENT_CATEGORY", currentCategory);
    },
    setSearchText: (context, pSearchText) => {
        context.commit("SET_SEARCH_TEXT", pSearchText);
    },
    setCurrentPage: (context, pPage) => {
        context.commit("SET_CURRENT_PAGE", pPage);
    },
};

// MUTATIONS
const mutations = {
    SET_CATALOG_ID: (state, pCatalogId) => {
        state.catalogId = pCatalogId;
    },
    SET_CURRENT_CATALOG_ID: (state, pCatalogId) => {
        state.ui.currentCatalogId = pCatalogId;
    },
    SET_CATEGORY_LIST: (state, payload) => {
        state.categoryList = payload;
    },
    SET_CURRENT_CATEGORY: (state, payload) => {
        state.ui.currentCategory = payload;
    },
    SET_ITEM_LIST: (state, payload) => {
        state.itemList = payload;
    },
    SET_ITEM_DETAIL: (state, pDetail) => {
        let itemId = pDetail.itemInfo.itemId;
        let idx = state.itemList.findIndex(
            (record) => record.itemId === itemId
        );
        if (idx >= 0) {
            pDetail.itemSizes.sort(function(a, b) {
                if (a.itemSize < b.itemSize) {
                    return -1;
                }
                if (a.itemSize > b.itemSize) {
                    return 1;
                }
                return 0;
            });

            state.itemList[idx].itemSizes = pDetail.itemSizes;
            state.itemList[idx].itemDescription =
                pDetail.itemInfo.itemDescription;
            state.itemList[idx].imageUrl = pDetail.itemImages[0].imageUrl;
        }
    },
    SET_SEARCH_TEXT: (state, payload) => {
        state.ui.searchText = payload;
    },
    SET_CURRENT_PAGE: (state, payload) => {
        state.ui.currentPage = payload;
    },
};

// GETTERS
const getters = {
    currentPage: (state) => {
        return state.ui.currentPage;
    },
    catalogItemList: (state) => {
        return state.itemList;
    },
    currentCategoryName: (state) => {
        return state.ui.currentCategory.categoryName;
    },
    serverItemsLength: (state) => {
        if (state.ui.searchText) {
            return state.itemList.length;
        }
        if (state.catalogId > 1) {
            return state.itemList.length;
        }
        return state.ui.currentCategory.catalogItemCount;
    },
    pageCount: (state) => {
        if (state.ui.searchText) {
            return 1;
        }
        let x = Math.round(
            state.ui.currentCategory.catalogItemCount / state.ui.itemsPerPage
        );
        let y =
            state.ui.currentCategory.catalogItemCount / state.ui.itemsPerPage;

        if (y > x) {
            x += 1;
        }
        if (x) return x;
        return 0;
    },
    itemsPerPage: (state) => {
        if (state.ui.searchText) {
            return 999; //state.itemList.length
        }
        return state.ui.itemsPerPage;
    },
    catalogTV: (state) => {
        let catalogTV = [];

        let x = state.categoryList.filter((record) => record.rowLevel <= 1);
        x.sort(function(a, b) {
            if (a.rowId < b.rowId) {
                return -1;
            }
            if (a.rowId > b.rowId) {
                return 1;
            }
            return 0;
        });

        x.forEach((record) => addToCatalog(record));

        function addToCatalog(record) {
            let catalogTVItem = {};
            catalogTVItem = {};
            catalogTVItem.id = record.categoryId;
            catalogTVItem.name = record.categoryName;
            catalogTVItem.catalogItemCount = record.catalogItemCount;
            catalogTVItem.children = [];

            addChildren(catalogTVItem);

            catalogTV.push(catalogTVItem);
        }

        function addChildren(parentItem) {
            let children = state.categoryList.filter(
                (record) => record.parentCategoryId === parentItem.id
            );

            if (children) {
                children.sort(function(a, b) {
                    if (a.id < b.id) {
                        return -1;
                    }
                    if (a.id > b.id) {
                        return 1;
                    }
                    return 0;
                });
                children.forEach((record) => {
                    let childItem = {};
                    childItem.id = record.categoryId;
                    childItem.name = record.categoryName;
                    childItem.catalogItemCount = record.catalogItemCount;
                    childItem.children = [];

                    addChildren(childItem);

                    parentItem.children.push(childItem);
                });
            }
        }

        return catalogTV;
    },
    searchText: (state) => {
        return state.ui.searchText;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
