import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"90%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-api")])],1),_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(_vm.apiHasErrors),expression:"apiHasErrors"}],attrs:{"small":"","color":"warning"}},[_vm._v("mdi-alert-circle-outline")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"elevation-10",attrs:{"rounded":""}},[_c(VCardTitle,[_vm._v("API LOG "),_c(VSpacer),_c(VDivider,{staticClass:"mx-2",attrs:{"vertical":""}}),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.apiInfo.length),expression:"apiInfo.length"}],attrs:{"text":"","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.resetApiInfo.apply(null, arguments)}}},[_vm._v("clear log")]),_c(VDivider,{staticClass:"mx-2",attrs:{"vertical":""}}),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close-circle-outline")])],1)],1),_c(VDivider,{staticClass:"mb-4"}),_c(VCardText,[_c(VDataTable,{attrs:{"item-key":"apiCall.sequenceId","hide-default-footer":"","disable-sort":"","disable-pagination":"","height":"400","fixed-header":"","items":_vm.apiInfo,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.apiCall.error",fn:function(ref){
var item = ref.item;
return [(item.apiCall.error)?_c(VIcon,{attrs:{"color":"warning"}},[_vm._v("mdi-alert-circle-outline")]):_vm._e()]}},{key:"item.apiCall.response",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(item.apiCall.response)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("copy response to clipboard")])])]}}],null,true)})],1),_c(VCardText,[_c(VSheet,{staticClass:"px-4",staticStyle:{"overflow-y":"auto"},attrs:{"outlined":"","max-height":"200"}},[_c(VSparkline,{attrs:{"value":this.apiSparkLine.values,"line-width":"1","label-size":"2","height":"30"},scopedSlots:_vm._u([{key:"label",fn:function(item){return [_vm._v(" "+_vm._s(item.value)+"ms ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }