<template>
	<v-app id="app">
		<!-- APP BAR -->
		<v-app-bar app short class="accent darken-2 white--text" flat dark>
			<v-spacer />

			<v-toolbar-title v-show="!sessionStarted" class="text-no-wrap">
				{{ tenantName }} <span class="mx-2">|</span>{{ appName }}
			</v-toolbar-title>
			<transition name="fade" mode="out-in">
				<v-btn-toggle group dark v-show="sessionStarted">
					<!-- home -->
					<v-btn large exact-path plain icon :to="{ name: 'Home' }"
						><v-icon>mdi-home-outline</v-icon></v-btn
					>
					<!-- productCatalog -->
					<v-btn
						large
						exact-path
						plain
						icon
						:to="{ name: catalogTypeName }"
						><v-icon>mdi-book-open-blank-variant</v-icon></v-btn
					>
					<!-- shoppingCart -->
					<v-btn
						v-show="webUserId > 0"
						large
						exact-path
						plain
						icon
						:to="{ name: 'ShoppingCart' }"
						><v-icon>mdi-cart-outline</v-icon></v-btn
					>
					<!-- account -->
					<v-btn
						v-show="webUserId > 0"
						large
						exact-path
						plain
						icon
						:to="{ name: 'Account' }"
					>
						<v-icon>mdi-account-circle</v-icon></v-btn
					>
				</v-btn-toggle>
			</transition>
			<v-spacer />
		</v-app-bar>

		<!-- MAIN -->
		<v-main class="grey lighten-5">
			<transition name="fade" mode="out-in">
				<keep-alive>
					<router-view> </router-view>
				</keep-alive>
			</transition>
		</v-main>

		<!-- APP FOOTER -->
		<v-footer app outlined padless>
			<ProfileDialog v-if="webUserId > 0" />
			<v-divider v-if="webUserId > 0" vertical class="mx-2" />
			<ApiInfoDialog
				v-if="
					webSessionId > 0 &&
					role === 'DEV' &&
					this.$vuetify.breakpoint.mdAndUp
				"
			/>
			<v-divider vertical class="mx-2" />
			<v-spacer />
			<v-divider vertical class="mr-2" />
			<v-btn-toggle v-model="currentLanguageId" exclusive group dense>
				<v-btn x-small text> NL </v-btn>
				<v-btn x-small text> FR </v-btn>
				<v-btn x-small text> EN </v-btn>
				<v-btn x-small text> DE </v-btn>
			</v-btn-toggle>
		</v-footer>

		<!-- BOTTOM SHEET -->
		<v-bottom-sheet v-model="bottomSheet.isVisible" dark hide-overlay>
			<v-sheet class="pa-2 text-center">
				<span>{{ bottomSheet.message }}</span>
				<v-btn right icon @click="bottomSheet = !bottomSheet"
					><v-icon class="pl-2">mdi-close-circle-outline</v-icon>
				</v-btn>
			</v-sheet>
		</v-bottom-sheet>
	</v-app>
</template>

<script>
	import { mapGetters } from "vuex";
	import ProfileDialog from "@/components/ProfileDialog";
	import ApiInfoDialog from "@/components/ApiInfoDialog";

	export default {
		name: "App",
		components: { ProfileDialog, ApiInfoDialog },
		data: () => ({
			appName: process.env.VUE_APP_APP_NAME,
			tenantName: process.env.VUE_APP_TENANT_NAME,
			searchText: "",
		}),
		computed: {
			...mapGetters({
				role: "session/role",
				webUserId: "session/webUserId",
				webSessionId: "session/webSessionId",
				sessionStarted: "session/sessionStarted",
				bottomSheet: "session/bottomSheet",
				webUserInfo: "session/webUserInfo",
			}),
			catalogTypeName() {
				if (this.webUserInfo[0].hasPrivateCatalog) {
					return "PrivateCatalog";
				} else {
					return "ProductCatalog";
				}
			},
			currentLanguageId: {
				get() {
					return this.$store.state.session.currentLanguageId - 1;
				},
				set(id) {
					this.$store.dispatch("session/setCurrentLanguage", id + 1);
				},
			},
		},
		methods: {
			logout() {
				this.$store.dispatch("session/webUserLogout");
			},
		},
	};
</script>

<style>
	/*** TRANSITIONS ***/
	.fade-enter {
		opacity: 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.8s ease-out;
	}

	.fade-leave-to {
		opacity: 0;
	}

	.slide-fade-enter {
		transform: translateX(10px);
		opacity: 0;
	}

	.slide-fade-enter-active,
	.slide-fade-leave-active {
		transition: all 0.2s ease;
	}

	.slide-fade-leave-to {
		transform: translateX(-10px);
		opacity: 0;
	}
</style>
