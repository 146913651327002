<template>
	<v-dialog v-model="dialog" max-width="90%">
		<template v-slot:activator="{ on, attrs }">
			<v-btn small icon v-bind="attrs" v-on="on">
				<v-icon>mdi-api</v-icon>
			</v-btn>
			<v-icon small v-show="apiHasErrors" color="warning"
				>mdi-alert-circle-outline</v-icon
			>
		</template>

		<v-card class="elevation-10" rounded>
			<v-card-title
				>API LOG
				<v-spacer />
				<v-divider vertical class="mx-2" />
				<v-btn
					v-show="apiInfo.length"
					text
					plain
					v-on:click.stop="resetApiInfo"
					>clear log</v-btn
				>
				<v-divider vertical class="mx-2" />
				<v-btn icon v-on:click.stop="dialog = false"
					><v-icon>mdi-close-circle-outline</v-icon></v-btn
				>
			</v-card-title>
			<v-divider class="mb-4" />
			<v-card-text>
				<v-data-table
					item-key="apiCall.sequenceId"
					hide-default-footer
					disable-sort
					disable-pagination
					height="400"
					fixed-header
					:items="apiInfo"
					:headers="headers"
				>
					<template v-slot:[`item.apiCall.error`]="{ item }">
						<v-icon v-if="item.apiCall.error" color="warning"
							>mdi-alert-circle-outline</v-icon
						>
					</template>
					<template v-slot:[`item.apiCall.response`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-bind="attrs"
									v-on="on"
									icon
									@click="
										copyToClipboard(item.apiCall.response)
									"
									><v-icon>mdi-content-copy</v-icon></v-btn
								>
							</template>
							<span>copy response to clipboard</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-card-text>
			<v-card-text>
				<v-sheet
					outlined
					class="px-4"
					max-height="200"
					style="overflow-y: auto"
				>
					<v-sparkline
						:value="this.apiSparkLine.values"
						line-width="1"
						label-size="2"
						height="30"
						><template v-slot:label="item">
							{{ item.value }}ms
						</template>
					</v-sparkline>
				</v-sheet>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
		name: "ApiInfoDialog",
		computed: {
			...mapGetters({
				apiHasErrors: "session/apiHasErrors",
				apiInfo: "session/apiInfo",
				apiSparkLine: "session/apiSparkLine",
				role: "session/role",
			}),
		},
		data() {
			return {
				dialog: false,
				headers: [
					{ text: "", value: "apiCall.error" },
					{ text: "call#", value: "apiCall.sequenceId" },
					{ text: "rpcName", value: "apiCall.rpcName" },
					{ text: "params", value: "apiCall.params" },
					{ text: "result", value: "apiCall.result" },
					{
						text: "duration (ms)",
						value: "apiCall.duration",
						align: "right",
					},
					{ text: "response", value: "apiCall.response" },
				],
			};
		},
		methods: {
			resetApiInfo() {
				this.$store.dispatch("session/resetApiInfo");
			},
			copyToClipboard(content) {
				document.addEventListener("copy", (e) => {
					e.clipboardData.setData("text/plain", content);
					e.preventDefault();
					document.removeEventListener("copy", e.clipboardData);
				});
				document.execCommand("copy");
				this.$store.dispatch(
					"session/showBottomMessage",
					"response is copied to clipboard"
				);
			},
		},
	};
</script>

