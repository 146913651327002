<template>
	<v-dialog v-model="dialog" :max-width="maxWidth">
		<template v-slot:activator="{ on, attrs }">
			<v-btn block x-large color="primary" v-bind="attrs" v-on="on">
				Login to start your session
			</v-btn>
		</template>

		<v-card class="elevation-10 mx-auto" rounded>
			<v-card-title>
				<span>{{ tenantName }}</span>
				<v-spacer />
				<v-btn icon v-on:click.stop="dialog = false"
					><v-icon>mdi-close-circle-outline</v-icon></v-btn
				>
			</v-card-title>
			<v-card-subtitle>{{ appName }}</v-card-subtitle>
			<v-sheet
				rounded
				outlined
				height="350"
				width="this.$vuetify.breakpoint.smAndDown ? '90%' | '350px'"
				class="mx-auto"
			>
				<v-container class="fill-height"
					><v-row align="center" justify="center"
						><v-col>
							<v-form
								lazy-validation
								v-model="valid"
								@keyup.native.enter="submitLogin"
							>
								<v-text-field
									v-model="userName"
									type="text"
									placeholder="Gebruiker"
									prepend-icon="mdi-account-circle"
									clearable
									:rules="rules"
								/>
								<v-text-field
									v-model="userPwd"
									:rules="rules"
									:type="showPassword ? 'text' : 'password'"
									placeholder="Wachtwoord"
									prepend-icon="mdi-lock"
									:append-icon="
										showPassword ? 'mdi-eye' : 'mdi-eye-off'
									"
									@click:append="showPassword = !showPassword"
								/>
							</v-form>
						</v-col>
						<v-col v-if="error" cols="12" align="center">
							<span class="red--text">
								{{ errMsg }}
							</span>
						</v-col>
					</v-row>
				</v-container>
			</v-sheet>
			<v-card-actions>
				<v-btn x-small plain @click.stop="resetPassword"
					>Password forgotten?</v-btn
				>
				<v-spacer />
				<v-btn
					:loading="loading"
					:disabled="!valid"
					text
					type="submit"
					color="primary"
					@click.stop="submitLogin"
				>
					<span class="px-2">Login</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
		name: "LoginDialog",
		data: () => ({
			dialog: false,
			valid: false,
			userName: "",
			userPwd: "",
			rules: [(v) => !!v || "verplicht"],
			showPassword: false,
			error: false,
			errMsg: "",
			loading: false,
			appName: process.env.VUE_APP_APP_NAME,
			tenantName: process.env.VUE_APP_TENANT_NAME,
		}),
		computed: {
			...mapGetters({
				webUserId: "session/webUserId",
			}),
			maxWidth() {
				if (this.$vuetify.breakpoint.smAndDown) return "100%";
				return "400";
			},
		},
		methods: {
			submitLogin() {
				const that = this;
				this.loading = true;
				window.setTimeout(function () {
					that.login();
				}, 750);
			},
			login() {
				this.loading = true;
				this.$store
					.dispatch("session/webUserLogin", {
						email: this.userName,
						password: this.userPwd,
					})
					.then(() => {
						this.$store
							.dispatch("session/setSessionStarted", true)
							.then(() => {
								localStorage.setItem("userName", this.userName);
								localStorage.setItem("userPwd", this.userPwd);
								this.dialog = false;
							});
					})
					.catch((error) => {
						this.error = true;
						this.errMsg = error;
					})
					.finally(() => {
						this.loading = false;
					});
			},
			resetPassword() {
				alert("to be implemented");
			},
		},
		mounted() {
			this.userName = localStorage.getItem("userName");
			this.userPwd = localStorage.getItem("userPwd");
		},
	};
</script>
