<template>
	<v-container fluid :class="{ fillHeight: showDisclaimer === true }">
		<!-- ABOUT -->
		<v-row justify="center">
			<v-col cols="12" lg="7">
				<v-card flat class="transparent">
					<v-card-title
						><v-icon class="mr-4">mdi-information-outline</v-icon
						>About</v-card-title
					>
					<v-card-text>
						<span>{{ getContent(2) }}</span>
						<a
							class="accent--text"
							:href="postman"
							target="_blank"
							style="text-decoration: none"
						>
							Postman</a
						>
						<span>.</span>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<!-- DISCLAIMER -->
		<v-row justify="center" v-if="showDisclaimer === true">
			<v-col cols="12" lg="7">
				<v-card flat class="transparent">
					<v-card-title
						><v-icon class="mr-4">mdi-alert-outline</v-icon
						>Disclaimer</v-card-title
					>
					<v-card-text>
						<span>{{ getContent(1) }}</span>
						<span v-show="readMore">{{ getContent(9) }}</span>
					</v-card-text>
					<v-card-actions>
						<v-btn
							v-if="!readMore"
							small
							text
							plain
							@click.stop="readMore = true"
							>Read more</v-btn
						>
						<v-btn
							v-if="readMore"
							class="ml-4"
							small
							plain
							outlined
							right
							color="primary"
							@click.stop="hideDisclaimer"
							>Understood
							<v-icon small class="pl-2">mdi-close</v-icon></v-btn
						>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<!-- SETTINGS -->
		<v-row justify="center">
			<v-col cols="12" lg="7">
				<v-card flat class="transparent">
					<v-card-title
						><v-icon class="mr-4">mdi-cogs</v-icon>Getting
						started</v-card-title
					>
					<v-card-subtitle>
						{{ getContent(8) }}
					</v-card-subtitle>
					<v-card-text>
						<v-row>
							<!-- ROLE -->
							<v-col cols="12" lg="4">
								<v-card outlined>
									<v-card-title class="text-subtitle-1"
										>Your role</v-card-title
									>
									<v-card-text>
										<v-radio-group
											hide-details
											v-model="role"
										>
											<v-radio
												:disabled="sessionStarted"
												label="DEVELOPMENT"
												value="DEV"
											></v-radio>
											<v-radio
												:disabled="sessionStarted"
												label="QUALITY ASSURANCE"
												value="QA"
											></v-radio>
										</v-radio-group>
									</v-card-text>
									<v-card-text v-show="role === 'DEV'">
										{{ getContent(3) }}
									</v-card-text>
									<v-card-text v-show="role === 'QA'">
										{{ getContent(4) }}
									</v-card-text>
								</v-card>
							</v-col>
							<!-- ENVIRONMENT -->
							<v-col cols="12" lg="4">
								<v-card outlined>
									<v-card-title class="text-subtitle-1"
										>Your environment</v-card-title
									>
									<v-card-text>
										<v-radio-group
											hide-details
											v-model="environment"
										>
											<v-radio
												:disabled="sessionStarted"
												label="LIVE"
												value="LIVE"
											></v-radio>
											<v-radio
												:disabled="sessionStarted"
												label="TEST"
												value="TEST"
											></v-radio>
										</v-radio-group>
									</v-card-text>
									<v-card-text
										v-show="environment === 'LIVE'"
									>
										{{ getContent(5) }}
									</v-card-text>
									<v-card-text
										v-show="environment === 'TEST'"
									>
										{{ getContent(6) }}
									</v-card-text>
								</v-card>
							</v-col>
							<!-- SESSIONTYPE -->
							<v-col cols="12" lg="4">
								<v-card outlined>
									<v-card-title class="text-subtitle-1"
										>Your session type</v-card-title
									>
									<v-card-text>
										<v-radio-group
											hide-details
											v-model="sessionType"
										>
											<v-radio
												:disabled="sessionStarted"
												label="ANONYMOUS"
												value="ANONYMOUS"
											></v-radio>
											<v-radio
												:disabled="sessionStarted"
												label="NAMED USER"
												value="NAMED"
											></v-radio>
										</v-radio-group>
									</v-card-text>
									<v-card-text
										v-show="sessionType === 'ANONYMOUS'"
									>
										{{ getContent(7) }}
									</v-card-text>
									<v-card-text
										v-show="sessionType === 'NAMED'"
									>
										{{ getContent(11) }}
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<!-- START/END SESSSION -->
		<v-row justify="center" v-show="role && environment && sessionType">
			<v-col cols="12" lg="7">
				<v-row justify="center">
					<v-col cols="12" lg="4">
						<transition name="fade" mode="out-in">
							<v-btn
								v-if="
									!sessionStarted &&
									sessionType === 'ANONYMOUS'
								"
								x-large
								color="primary"
								block
								:loading="loading"
								@click.stop="startSession"
								>Start your session</v-btn
							>
							<LoginDialog
								v-if="
									!sessionStarted && sessionType === 'NAMED'
								"
							/>
							<v-btn
								v-if="sessionStarted"
								x-large
								color="primary"
								block
								:loading="loading"
								@click.stop="endSession"
							>
								End your session
							</v-btn>
						</transition>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import homeContent from "@/assets/homeContent.json";
	import LoginDialog from "@/components/LoginDialog";
	import { mapGetters } from "vuex";

	export default {
		name: "Home",
		components: { LoginDialog },
		computed: {
			...mapGetters({
				webUserId: "session/webUserId",
				sessionStarted: "session/sessionStarted",
			}),
			role: {
				get() {
					let x = this.$store.state.session.settings.role;
					if (!x) {
						x = localStorage.getItem("role");
						if (x) this.$store.dispatch("session/setRole", x);
					}
					return x;
				},
				set(pRole) {
					localStorage.setItem("role", pRole);
					this.$store.dispatch("session/setRole", pRole);
				},
			},
			environment: {
				get() {
					let x = this.$store.state.session.settings.environment;
					if (!x) {
						x = localStorage.getItem("environment");
						if (x) this.$store.dispatch("session/setEnvironment", x);
					}
					return x;
				},
				set(pEnvironment) {
					localStorage.setItem("environment", pEnvironment);
					this.$store.dispatch("session/setEnvironment", pEnvironment);
				},
			},
			sessionType: {
				get() {
					let x = this.$store.state.session.settings.sessionType;
					if (!x) {
						x = localStorage.getItem("sessionType");
						if (x) this.$store.dispatch("session/setSessionType", x);
					}
					return x;
				},
				set(pSessionType) {
					localStorage.setItem("sessionType", pSessionType);
					this.$store.dispatch("session/setSessionType", pSessionType);
				},
			},
		},
		created() {
			var x = localStorage.getItem("showDisclaimer");
			if (x) {
				this.showDisclaimer = x;
			} else {
				this.showDisclaimer = true;
			}
		},
		data() {
			return {
				showDisclaimer: false,
				loading: false,
				postman: "https://documenter.getpostman.com/view/122953/Szzj6xHH",
				readMore: false,
			};
		},
		methods: {
			endSession() {
				if (this.webUserId) {
					this.$store.dispatch("session/webUserLogout");
				} else {
					location.reload();
				}
			},
			getContent(id) {
				let x = homeContent.find((record) => record.id === id);
				return x.text;
			},
			hideDisclaimer() {
				localStorage.setItem("showDisclaimer", false);
				this.showDisclaimer = false;
			},
			logout() {
				this.$store.dispatch("session/webUserLogout");
			},
			startSession() {
				this.loading = true;
				this.$store
					.dispatch("session/agentLogin")
					.then(() => {
						this.$store.dispatch("session/setSessionStarted", true);
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>


