import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Home from "@/views/Home";

Vue.use(VueRouter);

function lazyLoad(view) {
    return () => {
        return import(`@/views/${view}.vue`);
    };
}
function lazyLoadComponent(component) {
    return () => {
        return import(`@/components/${component}.vue`);
    };
}
const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { requiresAuth: false },
    },
    {
        path: "/catalog",
        name: "ProductCatalog",
        component: lazyLoad("ProductCatalog"),
        meta: { requiresAuth: false },
    },
    {
        path: "/privateCatalog",
        name: "PrivateCatalog",
        component: lazyLoad("PrivateCatalog"),
        meta: { requiresAuth: true },
    },
    {
        path: "/cart",
        name: "ShoppingCart",
        component: lazyLoad("ShoppingCart"),
        meta: { requiresAuth: true },
    },
    {
        path: "/account",
        name: "Account",
        component: lazyLoad("Account"),
        meta: { requiresAuth: true },
        children: [
            {
                path: "profile",
                name: "MyProfile",
                component: lazyLoadComponent("MyProfile"),
            },
            {
                path: "quotes",
                name: "MyQuotes",
                component: lazyLoadComponent("MyQuotes"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.name != "Home" && !store.getters["session/webSessionId"]) {
        next({ name: "Home" });
    }

    if (
        to.matched.some((record) => record.meta.requiresAuth) &&
        !store.getters["session/webUserId"]
    ) {
        next({ name: "Home" });
    }
    next();
});

export default router;
