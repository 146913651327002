import Vue from "vue";
import Vuex from "vuex";
import session from "@/store/modules/session";
import products from "@/store/modules/products";
import shoppingCart from "@/store/modules/shoppingCart";
import account from "@/store/modules/account";

Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        session,
        products,
        shoppingCart,
        account,
    },
});
export default store;
