import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"x-small":"","plain":"","text":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.webUserInfo[0].webUserName))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"elevation-10 mx-auto",attrs:{"rounded":""}},[_c(VCardTitle,[_vm._v("webUserInfo")]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.webUserInfo.webUserName))]),_c(VDivider),_c(VCardText,[_c(VDataTable,{attrs:{"hide-default-footer":"","disable-sort":"","disable-pagination":"","mobile-breakpoint":"5000","items":_vm.webUserInfo,"headers":_vm.headers}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","plain":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_vm._v("close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }