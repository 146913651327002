import caas from "@/services/caas";

// state
const state = {
    cart: [],
    cartItems: [],
    deliveryAddressId: 0,
    deliveryAddress: null,
    invoiceAddressId: 0,
    invoiceAddress: null,
    paymentCode: 0,
    remark: "",
    reference: "",
};
// getters
const getters = {
    cartItems: (state) => {
        // sort by createdOn
        if (state.cartItems) {
            state.cartItems.sort(function(a, b) {
                return new Date(a.createdOn) - new Date(b.createdOn);
            });
        }
        return state.cartItems;
    },
    cartItemCount: (state) => {
        if (state.cartItems) {
            return state.cartItems.length;
        } else {
            return 0;
        }
    },
    cart: (state) => {
        return state.cart;
    },
    deliveryAddressId: (state) => {
        return state.deliveryAddressId;
    },
    invoiceAddressId: (state) => {
        return state.invoiceAddressId;
    },
};
// actions
const actions = {
    convertShoppingCart: (context) => {
        var params = {
            cartId: context.state.cart.cartId,
            webUserId: null,
        };
        if (context.state.paymentCode) {
            params.paymentInfo = {
                paymentId: context.state.paymentCode,
                paymentRef: "n/a",
            };
        }
        params.orderInfo = {
            remark: context.state.remark,
            customerReference: context.state.reference,
        };
        if (context.state.deliveryAddressId > 0) {
            params.deliveryAddress = {
                addressId: context.state.deliveryAddressId,
            };
        }
        if (context.state.deliveryAddress) {
            Object.assign(
                params.deliveryAddress,
                context.state.deliveryAddress
            );
        }
        if (context.state.invoiceAddressId > 0) {
            params.invoiceAddress = {
                addressId: context.state.invoiceAddressId,
            };
        }
        if (context.state.invoiceAddress) {
            Object.assign(params.invoiceAddress, context.state.invoiceAddress);
        }
        return caas
            .rpc("convertShoppingCartToOrder", params)
            .then((response) => {
                context.commit("RESET_SHOPPING_CART");
                return response;
            })
            .catch((error) => {
                throw error;
            });
    },
    deleteCartItem: (context, pCartItem) => {
        let params = {
            cartItemId: pCartItem.cartItemId,
            webUserId: null,
        };
        return caas.rpc("deleteShoppingCartItem", params).then((response) => {
            context.commit("SET_SHOPPING_CART", response);
        });
    },
    fetchShoppingCart: (context) => {
        let params = {
            webUserId: null,
        };
        return caas.rpc("getShoppingCart", params).then((response) => {
            context.commit("SET_SHOPPING_CART", response);
        });
    },
    findProduct: (context, pProductCode) => {
        var x = {},
            y = {
                cartItemId: "",
                quantity: 0,
            };
        if (context.state.cartItems) {
            x = context.state.cartItems.find(
                (record) => record.productCode === pProductCode
            );
            if (x) {
                (y.cartItemId = x.cartItemId), (y.quantity = x.quantity);
            }
        }
        return y;
    },
    setTransportType: (context, params) => {
        params.webUserId = null;
        return caas.rpc("setTransportType", params).then((response) => {
            context.commit("SET_SHOPPING_CART", response);
        });
    },
    setDeliveryAddressId: (context, pId) => {
        context.commit("SET_DELIVERY_ADDRESS_ID", pId);
    },
    setDeliveryAddress: (context, pAddress) => {
        context.commit("SET_DELIVERY_ADDRESS", pAddress);
    },
    setInvoiceAddressId: (context, pId) => {
        context.commit("SET_INVOICE_ADDRESS_ID", pId);
    },
    setInvoiceAddress: (context, pAddress) => {
        context.commit("SET_INVOICE_ADDRESS", pAddress);
    },
    setPaymentCode: (context, pCode) => {
        context.commit("SET_PAYMENT_CODE", pCode);
    },
    setRemark: (context, pRemark) => {
        context.commit("SET_REMARK", pRemark);
    },
    setReference: (context, pReference) => {
        context.commit("SET_REFERENCE", pReference);
    },
    upsertCartItem: (context, pCartItem) => {
        var cartItems = [];
        cartItems.push(pCartItem);
        var params = { webUserId: null, cartItems };
        return caas.rpc("upsertShoppingCart", params).then((response) => {
            context.commit("SET_SHOPPING_CART", response);
        });
    },
};
// mutations
const mutations = {
    RESET_SHOPPING_CART: (state) => {
        state.cart = [];
        state.cartItems = [];
        state.deliveryAddressId = 0;
        state.deliveryAddress = null;
        state.invoiceAddressId = 0;
        state.invoiceAddress = null;
        state.paymentCode = 0;
        state.remark = "";
        state.reference = "";
    },
    SET_SHOPPING_CART: (state, payload) => {
        if (!payload) return;
        if (!payload.cart) return;
        state.cart = payload.cart[0];
        state.cartItems = payload.cart[0].cartItems;
    },
    SET_DELIVERY_ADDRESS: (state, pAddress) => {
        state.deliveryAddress = pAddress;
    },
    SET_DELIVERY_ADDRESS_ID: (state, pId) => {
        state.deliveryAddressId = pId;
    },
    SET_INVOICE_ADDRESS: (state, pAddress) => {
        state.invoiceAddress = pAddress;
    },
    SET_INVOICE_ADDRESS_ID: (state, pId) => {
        state.invoiceAddressId = pId;
    },
    SET_PAYMENT_CODE: (state, pCode) => {
        state.paymentCode = pCode;
    },
    SET_REMARK: (state, pRemark) => {
        state.remark = pRemark;
    },
    SET_REFERENCE: (state, pReference) => {
        state.reference = pReference;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
