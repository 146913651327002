import Vue from "vue";
import App from "@/App.vue";
import router from "@/router/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import moment from "moment";

Vue.config.productionTip = false;

Vue.filter("toDate", function(value) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
    }
});

Vue.filter("toCurrency", function(value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
    });
    return formatter.format(value);
});

Vue.filter("toNumber", function(value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("nl-BE", {
        minimumFractionDigits: 2,
    });
    return formatter.format(value);
});

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
