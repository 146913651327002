import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"app"}},[_c(VAppBar,{staticClass:"accent darken-2 white--text",attrs:{"app":"","short":"","flat":"","dark":""}},[_c(VSpacer),_c(VToolbarTitle,{directives:[{name:"show",rawName:"v-show",value:(!_vm.sessionStarted),expression:"!sessionStarted"}],staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.tenantName)+" "),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_vm._v(_vm._s(_vm.appName)+" ")]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c(VBtnToggle,{directives:[{name:"show",rawName:"v-show",value:(_vm.sessionStarted),expression:"sessionStarted"}],attrs:{"group":"","dark":""}},[_c(VBtn,{attrs:{"large":"","exact-path":"","plain":"","icon":"","to":{ name: 'Home' }}},[_c(VIcon,[_vm._v("mdi-home-outline")])],1),_c(VBtn,{attrs:{"large":"","exact-path":"","plain":"","icon":"","to":{ name: _vm.catalogTypeName }}},[_c(VIcon,[_vm._v("mdi-book-open-blank-variant")])],1),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.webUserId > 0),expression:"webUserId > 0"}],attrs:{"large":"","exact-path":"","plain":"","icon":"","to":{ name: 'ShoppingCart' }}},[_c(VIcon,[_vm._v("mdi-cart-outline")])],1),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.webUserId > 0),expression:"webUserId > 0"}],attrs:{"large":"","exact-path":"","plain":"","icon":"","to":{ name: 'Account' }}},[_c(VIcon,[_vm._v("mdi-account-circle")])],1)],1)],1),_c(VSpacer)],1),_c(VMain,{staticClass:"grey lighten-5"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('keep-alive',[_c('router-view')],1)],1)],1),_c(VFooter,{attrs:{"app":"","outlined":"","padless":""}},[(_vm.webUserId > 0)?_c('ProfileDialog'):_vm._e(),(_vm.webUserId > 0)?_c(VDivider,{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),(
				_vm.webSessionId > 0 &&
				_vm.role === 'DEV' &&
				this.$vuetify.breakpoint.mdAndUp
			)?_c('ApiInfoDialog'):_vm._e(),_c(VDivider,{staticClass:"mx-2",attrs:{"vertical":""}}),_c(VSpacer),_c(VDivider,{staticClass:"mr-2",attrs:{"vertical":""}}),_c(VBtnToggle,{attrs:{"exclusive":"","group":"","dense":""},model:{value:(_vm.currentLanguageId),callback:function ($$v) {_vm.currentLanguageId=$$v},expression:"currentLanguageId"}},[_c(VBtn,{attrs:{"x-small":"","text":""}},[_vm._v(" NL ")]),_c(VBtn,{attrs:{"x-small":"","text":""}},[_vm._v(" FR ")]),_c(VBtn,{attrs:{"x-small":"","text":""}},[_vm._v(" EN ")]),_c(VBtn,{attrs:{"x-small":"","text":""}},[_vm._v(" DE ")])],1)],1),_c(VBottomSheet,{attrs:{"dark":"","hide-overlay":""},model:{value:(_vm.bottomSheet.isVisible),callback:function ($$v) {_vm.$set(_vm.bottomSheet, "isVisible", $$v)},expression:"bottomSheet.isVisible"}},[_c(VSheet,{staticClass:"pa-2 text-center"},[_c('span',[_vm._v(_vm._s(_vm.bottomSheet.message))]),_c(VBtn,{attrs:{"right":"","icon":""},on:{"click":function($event){_vm.bottomSheet = !_vm.bottomSheet}}},[_c(VIcon,{staticClass:"pl-2"},[_vm._v("mdi-close-circle-outline")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }