import caas from "@/services/caas";
import store from "..";
import moment from "moment";
import router from "@/router/router";

// STATE
const state = {
    apiInfo: [],
    bottomSheet: {
        message: "",
        isVisible: false,
    },
    currentLanguageId: 1,
    webSessionId: 0,
    webUserInfo: {
        webUserId: 0,
    },
    settings: {
        role: "",
        environment: "",
        sessionType: "",
    },
    started: false,
};

// ACTIONS
const actions = {
    agentLogin: () => {
        return caas.rpc("agentLogin");
    },
    resetApiInfo: (context) => {
        context.commit("RESET_API_INFO");
    },
    setApiInfo: (context, pApiInfo) => {
        let x = state.apiInfo.length;
        if (!x) {
            x = 0;
        }
        x += 1;
        pApiInfo.apiCall.sequenceId = x;
        context.commit("SET_API_INFO", pApiInfo);
    },
    setCurrentLanguage: (context, pLanguageId) => {
        var x = context.state.currentLanguageId;
        if (x && x != pLanguageId) {
            //alert("switch languageId from " + x + " > " + pLanguageId);
            store.dispatch("products/emptyCatalog").then(() => {
                store.dispatch("products/fetchCatalog");
            });
        }
        context.commit("SET_CURRENT_LANGUAGE", pLanguageId);
    },
    setRole: (context, role) => {
        context.commit("SET_ROLE", role);
    },
    setEnvironment: (context, pEnvironment) => {
        var x = context.state.settings.environment;

        if (x != "" && x != pEnvironment) {
            //alert("switching environment: " + x + " > " + pEnvironment);
            context.dispatch("setWebSessionId", 0);
            context.dispatch("setWebUserInfo", {});
            context.dispatch("setWebUserId", 0);
        }
        context.commit("SET_ENVIRONMENT", pEnvironment);
    },
    setSessionStarted: (context, pStarted) => {
        context.commit("SET_SESSION_STARTED", pStarted);
    },
    setSessionType: (context, pSessionType) => {
        context.commit("SET_SESSION_TYPE", pSessionType);
    },
    setWebSessionId: (context, pSessionId) => {
        context.commit("SET_WEB_SESSION_ID", pSessionId);
        localStorage.setItem("webSessionId", pSessionId);
        localStorage.setItem("webSessionDayOfYear", moment().dayOfYear());
    },
    setWebUserId: (context, pWebUserId) => {
        context.commit("SET_WEB_USER_ID", pWebUserId);
        localStorage.setItem("webUserId", pWebUserId);
    },
    setWebUserInfo: (context, pWebUserInfo) => {
        context.commit("SET_WEB_USER_INFO", pWebUserInfo);
    },
    showBottomMessage(context, message) {
        var timeout = 3000;
        context.state.bottomSheet.message = message;
        context.state.bottomSheet.isVisible = true;
        window.setTimeout(function() {
            context.state.bottomSheet.isVisible = false;
        }, timeout);
    },
    webUserLogin: (context, pCredentials) => {
        return caas.rpc("webUserLogin", pCredentials).then((response) => {
            context.dispatch("setWebUserInfo", response);
            context.dispatch("setWebUserId", response.webUserId);
            context.dispatch("setCurrentLanguage", response.languageId);

            var catalogId = 1;
            if (
                response.hasPrivateCatalog === true &&
                response.privateCatalogId > 1
            ) {
                catalogId = response.privateCatalogId;
            }
            store.dispatch("products/setCatalogId", catalogId);
        });
    },
    webUserLogout: () => {
        let params = { webUserId: null };
        return caas.rpc("webUserLogoff", params).then(() => {
            router.push({ name: "Home" });
            location.reload();
        });
    },
};

// MUTATIONS
const mutations = {
    SET_APP_LAUNCHED: (state, pLaunched) => {
        state.appLaunched = pLaunched;
    },
    RESET_API_INFO: (state) => {
        state.apiInfo = [];
    },
    SET_API_INFO: (state, payload) => {
        state.apiInfo.push(payload);
    },
    SET_CURRENT_LANGUAGE: (state, pLanguageId) => {
        state.currentLanguageId = pLanguageId;
    },
    SET_ENVIRONMENT: (state, pEnvironment) => {
        state.settings.environment = pEnvironment;
    },
    SET_ROLE: (state, role) => {
        state.settings.role = role;
    },
    SET_SESSION_STARTED: (state, pStarted) => {
        state.started = pStarted;
    },
    SET_SESSION_TYPE: (state, pSessionType) => {
        state.settings.sessionType = pSessionType;
    },
    SET_WEB_SESSION_ID: (state, pSessionId) => {
        state.webSessionId = pSessionId;
    },
    SET_WEB_USER_ID: (state, pWebUserId) => {
        state.webUserInfo.webUserId = pWebUserId;
    },
    SET_WEB_USER_INFO: (state, pWebUser) => {
        state.webUserInfo = pWebUser;
    },
};

// GETTERS
const getters = {
    apiHasErrors: (state) => {
        return state.apiInfo.some((record) => record.error === true);
    },
    apiInfo: (state) => {
        let x = state.apiInfo;
        x.sort(function(a, b) {
            if (a.apiCall.sequenceId > b.apiCall.sequenceId) {
                return 1;
            }
            if (a.apiCall.sequenceId < b.apiCall.sequenceId) {
                return -1;
            }
        });
        return x;
    },
    apiSparkLine: (state, getters) => {
        var x = [];
        var z = [];
        var y = getters.apiInfo;
        if (y) {
            y.forEach((record) => x.push(record.apiCall.duration));
        }

        return { values: x, labels: z };
    },
    bottomSheet: (state) => {
        return state.bottomSheet;
    },
    currentLanguageId: (state) => {
        return state.currentLanguageId;
    },
    role: (state) => {
        return state.settings.role;
    },
    environment: (state) => {
        return state.settings.environment;
    },
    readOnly: (state) => {
        return (
            state.settings.environment === "LIVE" ||
            state.settings.sessionType === "ANONYMOUS"
        );
    },
    sessionStarted: (state) => {
        return state.started;
    },
    sessionType: (state) => {
        return state.settings.sessionType;
    },
    webSessionId: (state) => {
        if (state.webSessionId) return state.webSessionId;
        // try getting the id from localstorage
        var webSessionId = Number(localStorage.getItem("webSessionId")),
            dayOfYear = localStorage.getItem("webSessionDayOfYear");
        if (webSessionId && dayOfYear === moment().dayOfYear()) {
            state.commit("SET_WEB_SESSION_ID", webSessionId);
            return webSessionId;
        }
        // if nothing found
        return 0;
    },
    webUserId: (state) => {
        if (state.webUserInfo.webUserId) return state.webUserInfo.webUserId;
        // try getting the id from localstorage
        var webUserId = Number(localStorage.getItem("webUserId")),
            dayOfYear = localStorage.getItem("webSessionDayOfYear");
        if (webUserId && dayOfYear === moment().dayOfYear()) {
            state.commit("SET_WEB_USER_ID", webUserId);
            return webUserId;
        }
        // if nothing found
        return 0;
    },
    webUserInfo: (state) => {
        let x = [];
        x.push(state.webUserInfo);
        return x;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
